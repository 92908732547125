// GLOBAL STYLES HERE

body {
  margin: 0;
  background-color: $background;

  /*
  background: url(../svg/AditSystems/Rectangle-mobile.svg) no-repeat center center fixed;
  background-size: 100% 100%;
  object-fit: cover;

  @include tabletLandscape {
    background: url(../svg/AditSystems/Rectangle-desktop.svg) no-repeat center center fixed;
    width: 100%;
    svg {
      width: 100%;
    }
  }*/
}


@media all and (max-width: 1023px) {
  .hidden-mobile {
    display: none !important;
  }
}

.hidden-desktop {
  @include tabletLandscape {
    display: none !important;
  }
}



.resizeIPad {
  @include bigTablet {
    transform-origin: center;
    transform: scale(0.75);
    &--left {
      transform-origin: left center;
    }

    &--right {
      transform-origin: right center;
    }

    &--top {
      transform-origin: center top;
    }

    &--bottom {
      transform-origin: center bottom;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  picture {
    width: 100%;
    height: 100%;
    display: flex;
  }
}
