.contact-benefits {
  min-height: 110vw;
  @include tabletLandscape {
    min-height: 33vw;
  }

  &__container {
    display: flex;
    flex-direction: column;
    @include tabletLandscape {
      flex-direction: row;
      margin: 0 8.5vw;
    }
  }
  &__image {
    padding: 0 9%;

    @include tabletLandscape {
      width: 74%;
      padding: 0;
    }
    img {
      width: 100%;
    }
  }

  &__text {
    width: 100%;
    text-align: center;
    font-family: $fontNunito;
    margin-top: 6vw;
    line-height: 1.3;
    @include font-size(65px, 75px);
    @include compensatedLetterSpacing(0em);
    @include tabletLandscape {
      text-align: left;
      margin-top: 4vw;
    }
  }
  &__contacts {
    position: relative;
    @include tabletLandscape {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: left;
      padding-left: 5vw;
    }
  }
  &__contact {
    margin-top: 6vw;
    @include tabletLandscape {
      margin-top: 2vw;
    }

    &--spec {
      display: flex;
      align-items: center;
      justify-content: left;
      padding-left: 20vw;
      font-family: $fontNunito;
      margin-top: 4vw;
      @include font-size(40px, 35px);
      @include tabletLandscape {
        padding-left: 0;
        margin-top: 0vw;
        padding-bottom: 1vw;
      }
    }
    &--phone {
      padding-left: 2.5vw;
      @include tabletLandscape {
        padding-left: 1vw;
      }
    }
  }
}