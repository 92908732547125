// import variables here from main project
$fontBarlow: 'Barlow', sans-serif;
$fontDidot: 'Playfair Display', serif;
$fontPlayfair: 'Playfair Display', serif;
$fontBarlowCondensed: 'Barlow Condensed', sans-serif;
$fontBarlowSemiCondensed: 'Barlow Semi Condensed', sans-serif;

$fontNunito: 'Nunito Sans', sans-serif;
$fontKhula: 'Khula', sans-serif;

$fontBodoniM: 'Bodoni Moda', serif;

// colors
$background: #f9f9ff;

$blueAdit: #342df3;
$blueAditB: #1f237e;

$grey: #dadada;
$white: #ffffff;
$kind-of-black: #151515;
