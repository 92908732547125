.static-cards {
  min-height: 210.5vw;
  margin-top: 0;
  width: 100%;
  overflow-x: hidden;
  @include tabletLandscape {
    margin-top: 0;
    width: 100%;
    min-height: 46.18vw;
    margin-bottom: 2vw;
  }
  a {
    text-decoration: none;
    color: black;
  }
  &__title {
    @include tabletLandscape {
      margin-left: 1.5vw;
      margin-bottom: -1vw;
      margin-top: 2vw;
      font-size: 2.736vw;
      letter-spacing: 0.1em;
      font-family: $fontPlayfair;
      color: #b2000c;
      font-weight: 700;
    }
  }
  &__container {
    @include tabletLandscape {
      display: flex;
      width: 100%;
      top: 1.1vw;
      flex-direction: row;
      justify-content: center;
      margin: 0 1.6vw;
    }
  }

  &__fourImages {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @include tabletLandscape{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
    }
  }

  $fourImageMargin: 3.6%;

  &__images {
    margin: 2vw 1.5vw;
    min-height: 96.8vw;
    width: 45%;
    background-color: white;
    @include tabletLandscape {
      margin: 2vw 0.2vw;
      min-height: 39.24vw;
      width: 24%;
      position: relative;
    }
    img {
      width: 100%;
    }

    &.cta-overlay-opened {
      .static-cards__image {
        position: relative;
      }
      .static-cards__img {
        filter: grayscale(100%) brightness(80%) saturate(100%);
      }
      .ctas-to-be-shown {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include tabletLandscape {
          // show overlay on desktop, too
          display: flex;
          height: 40.62vw;
          padding-bottom: 10vw;
          .hce-btn.cta {
            width: 12vw;
            margin-top: 3vw;
          }
        }
      }
    }
  }
  &__title-img {
    color: black;
    bottom: 30.5vw;
    width: 100%;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 4vw;
    letter-spacing: 0.05em;
    &:lang(es) {
      font-size: 4vw;
    }
    @include tabletLandscape {
      font-size: 1.5vw;
      letter-spacing: 0.1em;
      bottom: 9.2vw;
      &:lang(es) {
        font-size: 1.5vw;
      }
    }
  }
  &__texts {
    padding: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    @include tabletLandscape {
      padding: 0;
      margin-top: 0;
    }
  }
  &__text {
    @include tabletLandscape {
      @include vw-value('font-size', 22px, 1440px);
      &:lang(es) {
        @include vw-value('font-size', 20px, 1440px)
      }
      &:lang(en) {
        @include vw-value('font-size', 19px, 1440px)
      }
    }
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 0.3vw;
  }
  &__subtext {
    @include tabletLandscape {
      @include vw-value(font-size, 45px, 2880px);
      margin-bottom: 1vw;
      letter-spacing: 0.025em;
      &:lang(es) {
        @include vw-value(font-size, 38px, 2880px);
      }
    }
  }
  &__ctas {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    @include tabletLandscape {
      bottom: 2.8vw;
    }
    .hce-btn.cta {
      width: 34vw;
      font-size: 3.2vw;
      padding: 0;
      @include tabletLandscape {
        width: 15vw;
        font-size: 1vw;
        padding: 0.7vw 0;
      }
    }
  }
  &__cta {
    margin: 0 2vw 0 0;
    @include tabletLandscape {
      width: 8.5vw;
      .hce-btn.cta {
        width: 8.5vw;
      }
    }
  }
  &.three-cards {
    @include standardComponentMargin;
    min-height: 114vw;
    @include tabletLandscape {
      min-height: 25vw;
      @include standardComponentMargin;
    }
    .static-cards {
      width: 90vw;
      &__fourImages {
        margin: 0 0 0 2vw;
        @include tabletLandscape {
          margin: 0;
        }
      }
      &__wrapper {
        @include tabletLandscape {
          display: flex;
          justify-content: space-around;
        }
      }
      &__svg-image {
        z-index: 1;
        width: 80%;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        bottom: 40%;
        @include tabletLandscape {
          bottom: 26%;
          width: 67%;
        }
        img {
          width: 100%;
        }
      }
      &__container {
        @include tabletLandscape {
          margin: 0;
          width: 90%;
          padding: 0 5%;
        }
      }
      &__images {
        min-height: 114vw;
        width: 74vw;
        margin: 0;
        position: relative;
        @include tabletLandscape {
          min-height: 25vw;
          width: 25vw;
          margin: 0 1.5vw;
          position: relative;

        }
      }
      &__texts {
        position: absolute;
        bottom: 1vw;
        text-align: left;
        @include tabletLandscape {
          position: absolute;
          bottom: 1vw;
        }
      }
      &__text {
        position: absolute;
        bottom: 21vw;
        color: white;
        width: 100%;
        text-align: center;
        text-transform: none;
        font-weight: 500;
        @include font-size(40px, 35px);
        line-height: 1.3;
        letter-spacing: .05em;
        &:lang(en) {
          @include font-size(38px, 35px);
        }
        &:lang(es) {
          font-size: 2.9vw;
        }
        @include tabletLandscape {
          bottom: 6.5vw;
          color: white;
          width: 100%;
          &:lang(es) {
            font-size: 1.1vw;
          }
        }
      }
      &__subtext, &__subtext1, &__subtext2, &__subtext3 {
        position: absolute;
        color: white;
        width: 100%;
        text-align: left;
        left: 6vw;
        @include tabletLandscape {
          position: absolute;
          bottom: 11vw;
          color: white;
          width: 100%;
          left: 1.5vw;

        }
      }
      &__subtext {
        @include font-size(115px, 90px);
        font-weight: 900;
        letter-spacing: 0.08em;
        bottom: 56vw;
        text-transform: uppercase;
        @include tabletLandscape {
          position: absolute;
          bottom: 15vw;

        }

      }
      &__subtext1 {
        @include font-size(43px, 33px);
        font-weight: bold;
        text-transform: uppercase;
        bottom: 52.3vw;
        &:lang(en) {
          font-size: 3.6vw;
        }
        @include tabletLandscape {
          bottom: 14.9vw;
          &:lang(en) {
            font-size: 1.08vw;
          }

        }
      }
      &__subtext2 {
        font-family: $fontPlayfair;
        font-style: italic;
        @include font-size(44px, 35px);
        @include line-height(50px, 35px);
        bottom: 20.5vw;
        @include tabletLandscape {
          bottom: 5.9vw;
        }
      }
      &__subtext3 {
        @include font-size(100px, 100px);
        bottom: 5.8vw;
        @include tabletLandscape {
          bottom: 5.8vw;

        }
      }
      &__ctas {
        position: absolute;
        bottom: 6.75vw;
        display: flex;
        justify-content: center;
        left: 0;
        @include tabletLandscape {
          position: absolute;
          bottom: 2.3vw;
          display: flex;
          justify-content: center;


        }
      }
      &__cta {
        margin: 0 .5vw;
        @include tabletLandscape {
          margin: 0 .5vw;

        }
        .hce-btn.cta {
          font-weight: normal;
          width: 32vw;
          padding: 2.4vw;
          font-size: 3.5vw;
          @include tabletLandscape {
            width: 9vw;
            padding: 0.6vw;
            font-size: 1vw;
          }
        }
      }
    }
  }
  &.static-cards-cloud-svg {
    @include standardComponentMargin;
    min-height: 85vw;
    @include tabletLandscape {
      min-height: 18vw;
      @include standardComponentMargin;
    }
    .static-cards {
      &__container {
        padding: 0 5vw;
        @include tabletLandscape {
          padding: 0;
          margin: 0;
        }
      }
      &__fourImages {
        justify-content: space-between;
        align-items: center;
        height: 85vw;
        @include tabletLandscape {
          justify-content: center;
          align-items: center;
          height: 18vw;

        }
      }
      &__images {
        min-height: 38vw;
        width: 40.5vw;
        margin: 1.5vw;
        position: relative;
        border-radius: 10px;
        box-shadow: 0px 0px 8px .2px rgba(196,196,196,0.5);
        cursor: pointer;
        @include tabletLandscape {
          min-height: 15vw;
          width: 18vw;
          margin: 0 1.5vw;
          position: relative;
          box-shadow: 0px 0px 15px .5px rgba(196,196,196,0.5);

        }
        &--active {
          border-bottom: 2px solid blue;
          transform: scale(1.01);
        }
      }

      &__svg-image {
        z-index: 1;
        width: 40%;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        bottom: 41%;
        @include tabletLandscape {
          bottom: 38%;
          width: 35%;
        }
        img {
          width: 100%;
        }
      }
      &__texts {
        position: absolute;
        bottom: 1vw;
        text-align: left;
        font-family: $fontNunito;
        @include tabletLandscape {
          position: absolute;
          bottom: 1vw;
        }
      }
      &__text {
        position: absolute;
        bottom: 5vw;
        color: black;
        width: 100%;
        text-align: center;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0.015em;
        @include font-size(44px, 35px);
        @include line-height(50px, 40px);
        @include tabletLandscape {
          bottom: 1.5vw;
          width: 100%;
        }
      }
      &__ctas {
        position: absolute;
        bottom: 6.75vw;
        display: flex;
        justify-content: center;
        left: -1.9vw;
        @include tabletLandscape {
          position: absolute;
          bottom: 2.3vw;
          display: flex;
          justify-content: center;
          left: -4.8vw;
        }
      }
      &__cta {
        margin: 0 .5vw;
        @include tabletLandscape {
          margin: 0 .5vw;

        }
        .hce-btn.cta {
          font-weight: bold;
          width: 55vw;
          padding: 2.4vw;
          font-family: $fontNunito;
          @include tabletLandscape {
            font-weight: bold;
            width: 18vw;
            padding: 0.6vw;
          }
        }
      }
    }
  }
  &.static-cards-price-services {
    @include standardComponentMargin;
    min-height: 65.5vw;
    @include tabletLandscape {
      min-height: 30vw;
      @include standardComponentMargin;
    }
    .static-cards {
      &__container {
        padding: 0 5vw;
        @include tabletLandscape {
          padding: 0;
          margin: 0;
        }
      }
      &__fourImages {
        justify-content: center;
        flex-wrap: wrap;
        @include tabletLandscape {
          justify-content: center;
          flex-wrap: nowrap;

        }
      }
      &__images {
        min-height: 80vw;
        width: 100%;
        margin: 1.5vw;
        position: relative;
        border-radius: 10px;
        @include tabletLandscape {
          min-height: 30vw;
          width: 26vw;
          margin: 0 1.5vw;
          position: relative;

        }
      }

      &__svg-image {
        z-index: 1;
        width: 40%;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        bottom: 41%;
        @include tabletLandscape {
          bottom: 38%;
          width: 35%;
        }
        img {
          width: 100%;
        }
      }
      &__texts {
        font-family: $fontNunito;
        position: absolute;
        bottom: unset;
        top: 0;
        text-align: left;
        @include tabletLandscape {
          position: absolute;
        }
      }
      &__text {
        position: absolute;
        bottom: unset;
        top: 7vw;
        color: black;
        width: 100%;
        text-align: center;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0.015em;
        @include font-size(44px, 35px);
        @include line-height(50px, 40px);
        @include tabletLandscape {
          top: 2vw;
          width: 100%;
        }
      }
      &__price {
        position: absolute;
        bottom: unset;
        top: 12vw;
        color: black;
        width: 100%;
        text-align: center;
        text-transform: none;
        font-weight: normal;
        letter-spacing: 0.015em;
        @include font-size(44px, 35px);
        @include line-height(50px, 40px);
        @include tabletLandscape {
          top: 5vw;
          width: 100%;
        }
      }
      &__servicesList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        top: 20vw;
        width: 80vw;
        padding: 0 15vw;
        @include tabletLandscape {
          top: 10vw;
          width: 20vw;
          padding: 0 5vw;
        }

      }
      &__list {
        display: flex;
        margin: 0.6vw 0;
        @include tabletLandscape {
          margin: 0.6vw 0;
        }
      }

      &__icon {
        width: 10%;
        @include tabletLandscape {
          width: 10%;
        }
        img {
          width: 100%;
        }
      }
      &__ctas {
        position: absolute;
        bottom: 6.75vw;
        display: flex;
        width: 100%;
        justify-content: center;
        left: unset;
        @include tabletLandscape {
          position: absolute;
          bottom: 2.3vw;
          display: flex;
          justify-content: center;
        }
      }
      &__cta {
        margin: 0;
        width: unset;
        @include tabletLandscape {
          margin: 0;
        }
        .hce-btn.cta {
          font-family: $fontNunito;
          font-weight: bold;
          width: 60vw;
          padding: 2.4vw;
          font-size: 3.2vw;
          text-transform: none;
          @include tabletLandscape {
            font-weight: bold;
            width: 12vw;
            padding: 0.6vw;
            font-size: .85vw;
          }
        }
      }
    }
  }

  &.ourBrands-three-cards {
    min-height: 114vw;
    margin: 2vw 0;
    @include tabletLandscape {
      min-height: 34vw;
      padding: 0;
      margin: 0.2vw 0;
    }
    .static-cards {
      &__fourImages {
        margin: 0 0 0 0.5vw;
        padding: 0 1vw 0 0;
        @include tabletLandscape {
          margin: 0;
          padding: 0;
        }
      }
      &__wrapper {
        @include tabletLandscape {
          display: flex;
          justify-content: space-between;
        }
      }
      &__container {
        @include tabletLandscape {
          margin: 0;
        }
      }
      &__images {
        min-height: 114vw;
        width: 74%;
        margin: 0;
        position: relative;
        @include tabletLandscape {
          min-height: 43vw;
          width: 33.2%;
          margin: 0;
          position: relative;

        }
      }
      &__texts {
        position: absolute;
        bottom: 1vw;
        text-align: left;
        @include tabletLandscape {
          position: absolute;
          bottom: 1vw;
        }
      }
      &__text {
        position: absolute;
        bottom: 18vw;
        left: 3.5vw;
        color: white;
        width: 100%;
        text-align: left;
        text-transform: none;
        font-weight: bold;
        @include font-size(75px, 60px);
        line-height: 1.3;
        @include compensatedLetterSpacing(0.25em);
        &:lang(en) {
          @include font-size(72px, 60px);
          @include compensatedLetterSpacing(0.23em);
        }
        @include tabletLandscape {
          bottom: 4.5vw;
          color: white;
          width: 100%;
          @include compensatedLetterSpacing(0.2em);
          &:lang(en) {
            @include compensatedLetterSpacing(0.14em);
          }
        }
      }
      &__ctas {
        display: unset;
        position: absolute;
        bottom: 3.75vw;
        left: 3.5vw;
        @include tabletLandscape {
          position: absolute;
          bottom: 2.3vw;
        }
      }
      &__cta {
        margin: 0 3vw;
        @include tabletLandscape {
           margin: 0 1vw;

        }
        .hce-btn.cta {
          font-weight: normal;
          width: 34vw;
          padding: 1.5vw;
          font-size: 3.5vw;
          @include tabletLandscape {
            width: 8vw;
            padding: 0.4vw;
            font-size: .9vw;
          }
        }
      }
    }
  }
  &.video-earth-day {
    min-height: 105vw;
    @include tabletLandscape {
      min-height: 5vw;
      margin-bottom: 1vw;
      margin-top: 1vw;
    }
    .static-cards {
      &__video {
        width: 100%;
      }
      &__images {
        margin: 0 0.8vw;
        min-height: 90vw;
        @include tabletLandscape {
          width: 23.8%;
          margin: 0 0.2vw;
          min-height: 36vw;
        }
      }

      &__container {
        @include tabletLandscape {
          margin: 0;
        }
      }

      &__wrapper {
        @include tabletLandscape {
          display: flex;
          justify-content: center;
        }
      }

      &__subtext, &__subtext1, &__subtext2, {
        color: black;
      }

      &__subtext {
        font-size: 8vw;
        font-weight: 700;
        letter-spacing: 0.08em;
        bottom: 53vw;
        text-transform: uppercase;
        line-height: 1.2;
        text-align: center;
        left: 0;
        @include font-size(78px, 58px);
        &:lang(es) {
          @include font-size(68px, 52px);
          letter-spacing: 0.01em;
        }
        @include tabletLandscape {
          position: absolute;
          bottom: 25vw;
          line-height: 1.35;
          @include compensatedLetterSpacing(0.1em);
          left: -1.5vw;
          &:lang(es) {
            @include compensatedLetterSpacing(0.05em);
          }
        }
      }
      &__subtext2 {
        font-family: $fontBarlow;
        @include font-size(44px, 35px);
        @include line-height(50px, 41px);
        @include compensatedLetterSpacing(0.1em);
        bottom: 17.5vw;
        left: 0;
        font-weight: 400;
        font-style: unset;
        text-align: center;
        @include tabletLandscape {
          bottom: 14.9vw;
          left: -1.5vw;
        }
      }
      &__title-video {
        text-align: center;
        color: black;
        text-decoration: none;
        margin-top: 1vw;
        @include font-size(55px, 40px);
        @include compensatedLetterSpacing(0.05em);
        @include tabletLandscape {
          margin-top: 0.3vw;
        }
      }
      &__cta {
        .hce-btn.cta {
          width: 32vw;
          font-weight: normal;
          &:lang(en), &:lang(es) {
            width: 36vw;
          }
          @include tabletLandscape {
            width: 9vw;
            letter-spacing: 0.1em;
            &:lang(en), &:lang(es)  {
              width: 11vw;
            }
          }
        }
      }
      &__ctas {
        bottom: -15vw;
        @include tabletLandscape {
          width: 100%;
          bottom: 6.5vw;
          left: -1.5vw;
          &:lang(en) {
            left: -3vw;
          }
        }
      }
      &__line {
        background-color: black;
        width: 52vw;
        height: 0.5vw;
        position: absolute;
        bottom: 43.5vw;
        left: 9vw;
        @include tabletLandscape {
          bottom: 23vw;
          width: 16vw;
          height: 0.2vw;
          left: 2vw;
        }
      }
    }
  }
  &.three-cards-only-text {
    @include standardComponentMargin;
    min-height: 90vw;
    @include tabletLandscape {
      min-height: 34vw;
      margin-top: 0.4vw;
      margin-bottom: 2.666664vw;
    }
    .static-cards {
      &__fourImages {
        margin: 0 0 0 3vw;
        @include tabletLandscape {
          margin: 0 1vw;
        }
      }
      &__wrapper {
        @include tabletLandscape {
          display: flex;
          justify-content: space-between;
        }
      }
      &__container {
        @include tabletLandscape {
          margin: 0;
        }
      }
      &__images {
        min-height: 86vw;
        width: 70vw;
        margin: 0 1vw;
        position: relative;
        border: 2vw solid #f5f1eb;
        @include tabletLandscape {
          min-height: 32vw;
          width: 30vw;
          margin: 0;
          position: relative;
          border: 1vw solid #f5f1eb;

        }
      }
      &__texts {
        position: absolute;
        top: 0;
        text-align: left;
        left: 3.5vw;
        @include tabletLandscape {
          position: absolute;
          top: 0;
          left: 1vw;
        }
      }
      &__text {
        font-family:$fontBodoniM;
        position: absolute;
        top: 1vw;
        width: 100%;
        text-align: left;
        text-transform: none;
        font-weight: 500;
        @include font-size(210px, 210px);
        line-height: 1.3;
        letter-spacing: .05em;
        @include tabletLandscape {
          top: 0.5vw;
          width: 100%;
        }
      }
      &__text1 {
        position: absolute;
        top: 25vw;
        width: 100%;
        text-align: left;
        text-transform: none;
        font-weight: 500;
        @include font-size(52px, 46px);
        line-height: 1.3;
        letter-spacing: .03em;
        @include tabletLandscape {
          top: 10vw;
          width: 100%;
          letter-spacing: .04em;
        }
      }

    }
  }
  &.three-cards-only-text-svg {
    .static-cards {
      &__fourImages {
        margin: 0 0 0 3vw;
        @include tabletLandscape {
          margin: 0;
        }
      }
      &__wrapper {
        @include tabletLandscape {
          display: flex;
          justify-content: space-around;
        }
      }
      &__svg-image {
        z-index: 1;
        width: 47%;
        transform: translateX(-50%);
        left: 50%;
        position: absolute;
        bottom: 57%;
        @include tabletLandscape {
          bottom: 53%;
          width: 47%;
        }
        img {
          width: 100%;
        }
      }
      &__container {
        @include tabletLandscape {
          margin: 0;
        }
      }
      &__images {
        min-height: 86vw;
        width: 74%;
        margin: 0 1vw;
        position: relative;
        border: unset;
        &.first-card {
         background-color: #7fc0bd;
        }
        &.second-card {
          background-color: #9ebe43;
        }
        &.third-card {
          background-color: #e69532;
        }

        @include tabletLandscape {
          min-height: 32vw;
          width: 33%;
          margin: 0 0.2vw;
          position: relative;

        }
      }
      &__texts {
        position: absolute;
        text-align: center;
        left: 0;
        @include tabletLandscape {
          position: absolute;
        }
      }
      &__text {
        font-family: $fontBarlow;
        position: absolute;
        width: 100%;
        color: $white;
        text-align: center;
        text-transform: none;
        font-weight: 700;
        @include font-size(90px, 70px);
        line-height: 1.3;
        top: 34vw;
        @include compensatedLetterSpacing(0.05em);
        &:lang(en) {
          font-size: 7vw;
        }
        &:lang(es) {
          font-size: 7vw;
        }
        @include tabletLandscape {
          width: 100%;
          @include compensatedLetterSpacing(0.1em);
          top: 16vw;
          &:lang(en) {
            @include font-size(90px, 65px);
          }
          &:lang(es) {
            @include font-size(90px, 65px);
          }
        }
      }
      &__text1 {
        position: absolute;
        top: 44vw;
        width: 100%;
        text-align: center;
        text-transform: none;
        font-weight: 500;
        @include font-size(48px, 35px);
        line-height: 1.3;
        @include compensatedLetterSpacing(0.04em);
        @include tabletLandscape {
          top: 19.5vw;
          width: 100%;
          @include compensatedLetterSpacing(0.05em);
        }
      }
      &__ctas {
        position: absolute;
        bottom: 4.5vw;
        display: flex;
        justify-content: center;
        @include tabletLandscape {
          bottom: 2.5vw;
        }
      }
      &__cta {
        margin: 0;
        width: 60vw;
        @include tabletLandscape {
          width: 23vw;
        }
        .hce-btn.cta {
          width: 60vw;
          color: #9ebe43 !important;
          font-weight: bold;
          padding: 1.8vw;
          font-size: 3.5vw;
          @include tabletLandscape {
            width: 23vw;
            padding: 0.6vw;
            font-size: 1.2vw;
          }
        }
      }
    }
  }

}

