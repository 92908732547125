.hce-btn.cta {
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  padding: 0;
  background: transparent;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-block;
  height: auto;
  transition: all 0.2s ease-out;

  &, &:visited, &:hover {
    text-decoration: none;
  }

  @include dpricesmallblack;
  color: $kind-of-black !important;
  @include vw-value('width', 330px, 375px);
  @include vw-value('font-size', 40px, 1125px);
  line-height: 1.3 !important;
  @include vw-value('padding-top', 20px, 1125px);
  @include vw-value('padding-bottom', 20px, 1125px);

  &:visited {
    text-decoration: none;
    color: inherit;
  }

  &[data-type="primary"] {

  }

  &[data-type="secondary"] {
  }

  &[data-type="tertiary"] {
  }

  &[data-type="buynow"] {
    width: 13%;
    margin: 0 auto;
  }

  &[data-outline="black"] {
    border: 1px solid black;
  }

  &[data-outline="white"] {
    border: 1px solid $white;
  }


  &[data-outline="blueAdit"] {
    border: 0.3px solid $blueAdit;
  }


  @media all and (max-width: 1023px) {


    &[data-mobile-color="blueAdit"] {
      background: $blueAdit;
      color: white !important;
      border-color: $blueAdit;

      &:hover, &.hover {
        background: white;
        color: $blueAdit !important;
      }
    }
    &[data-mobile-color="blueAditT"] {
      background: $white;
      color: $blueAdit !important;
      border-color: $blueAdit;

      &:hover, &.hover {
        background: $blueAdit;
        color: $white !important;
      }
    }


    &[data-mobile-color="white"] {
      background: $white;

      &:hover, &.hover {
        background: $kind-of-black;
        color: $white !important;
      }
    }


    &[data-mobile-color="black"] {
      background-color: $kind-of-black;
      color: $white !important;

      &:hover, &.hover {
        background-color: $white;
        color: $kind-of-black !important;
      }
    }

    &[data-mobile-color="transparent"] {
      background-color: transparent;
      color: $kind-of-black !important;

      &:hover, &.hover {
        background-color: $kind-of-black;
        color: $white !important;
      }
    }
    &[data-mobile-color="transparentW"] {
      background-color: transparent;
      color: $white !important;
      border-color: $white !important;

      &:hover, &.hover {
        background-color: $white;
        color: $kind-of-black !important;
      }
    }
  }

  @include tabletLandscape {
    @include mpricesmallwhite;
    color: $white !important;
    @include vw-value('width', 390px, 2880px);
    @include vw-value('font-size', 28px, 2880px);
    @include vw-value('padding-top', 20px, 2880px);
    @include vw-value('padding-bottom', 24px, 2880px);
    min-width: 0;
    max-width: none;
    leter-spacing: 0.1em;

    &:hover, &.hover {
      background: $white;
      color: $kind-of-black !important;
    }

    &[data-desktop-color="black"] {
      background: $kind-of-black;

      &:hover, &.hover {
        background: $white;
        color: $kind-of-black !important;
      }
    }


    &[data-desktop-color="blueAdit"] {
      background: $blueAdit;
      color: white !important;
      border-color: $blueAdit;

      &:hover, &.hover {
        background: white;
        color: $blueAdit !important;
      }
    }
    &[data-desktop-color="blueAditT"] {
      background: $white;
      color: $blueAdit !important;
      border-color: $blueAdit;

      &:hover, &.hover {
        background: $blueAdit;
        color: $white !important;
      }
    }


    &[data-desktop-color="white"] {
      background: $white;
      @include mpricesmallblack;
      @include vw-value('font-size', 28px, 2880px);
      color: $kind-of-black !important;

      &:hover, &.hover {
        color: $white !important;
        background: $kind-of-black;
      }
    }
    &[data-desktop-color="transparent"] {
      background: transparent;
      color: $kind-of-black !important;

      &:hover, &.hover {
        background-color: $kind-of-black;
        color: $white !important;
      }
    }

    &[data-desktop-color="transparentW"] {
      background-color: transparent;
      border-color: $white;
      color: $white !important;

      &:hover, &.hover {
        background: $white;
        color: $kind-of-black !important;
      }
    }


  }

}
