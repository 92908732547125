.header {
  position: fixed;
  top: 0;
  overflow-x: hidden;
  z-index: 99;
  left: 0;
  background-color: $blueAditB;
  width: 100%;

  &__container {
    display: flex;
    background-color: $blueAditB;
    position: relative;
    height: 14vw;
    transition: height 600ms ease-in;
    @include tabletLandscape {
      width: 80%;
      height: 4.5vw;
      display: flex;
      align-items: center;
      margin: 0 10%;
    }
  }

  &__container--active {
    height: 100vw;
    z-index: 2;
    opacity: 1;
  }
  .hamburger {
    position: absolute;
    top: 0;
    right: 1vw;
    z-index: 2;
  }
  &__logo {
    position: absolute;
    top: 4vw;
    left: 1vw;

    @include tabletLandscape {
      position: unset;
      display: flex;
      justify-content: left;
      align-items: center;
      width: 30%;
      img {
        width: 80%;
      }
    }
  }
  &__list {
    display: none;
    font-family: $fontNunito;
    @include tabletLandscape {
      display: block;
      width: 70%;

      ul {
        @include tabletLandscape {
          width: 90%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
      li {
        padding: 0;
        font-size: 1.2vw;
      }
    }
  }


  &__list-mobile {
    font-family: $fontNunito;
    display: none;
  }
  &__list-mobile--show {
    display: block;
    transform: translateX(-6vw);
    margin-top: 12vw;
    font-size: 5.5vw;
    ul {
      width: 100%;
    }
    li {
      margin: 3vw 0;
      text-align: left;
    }

  }

  nav ul {
    list-style-type: none;
  }
  a {
    text-decoration: none;
    color: white;
    padding: 7px 10px;
    &.diff-link {
      background-color: $blueAdit;
    }
  }
  a:hover {
    &.diff-link {
      background-color: $white;
      color: black;
    }
  }

}