.title-with-description {
  overflow-x: hidden;
  @include vw-value('margin-top', 90px, 1125px);
  @include vw-value('margin-bottom', 0px, 1125px);
  @include vw-value('padding-left', 40px, 1125px);
  @include vw-value('padding-right', 40px, 1125px);
  position: relative;

  @include bigTablet {
    @include vw-value('margin-top', 84px, 1125px);
    @include vw-value('margin-bottom', 84px, 1125px);
  }
  @include tabletLandscape {
    @include vw-value('margin-top', 90px, 2880px);
    @include vw-value('margin-bottom', 50px, 2880px);
    @include vw-value('padding-left', 80px, 2880px);
    @include vw-value('padding-right', 80px, 2880px);

  }

  &--noTopMargin {
    @include tabletLandscape {
      margin-top: 0;

    }
  }
  &.text-cambiamento-landing {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0 2vw;
    @include tabletLandscape {
      min-height: 1vw;
      margin-top: 3vw;
      margin-bottom: 2vw;
      padding: 0;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        font-size: 5.9vw;
        line-height: 1.2;
        margin-bottom: 3vw;
        text-transform: uppercase;
        @include compensatedLetterSpacing(0.3em);
        @include tabletLandscape {
          font-size: 2.1vw;
          margin-bottom: .8vw;
          line-height: 1;
          @include compensatedLetterSpacing(0.4em);
        }
      }

      &__desc {
        font-size: 4.5vw;
        line-height: 1.25;
        @include compensatedLetterSpacing(0.15em);

        &:lang(en) {
        }

        &:lang(es) {
          font-size: 3.8vw;
        }

        @include tabletLandscape {
          font-size: 1.3vw;
          line-height: 1.3;
          &:lang(en) {
            font-size: 1.15vw;
          }
          &:lang(es) {
            font-size: 1.15vw;
          }
        }
      }
    }
  }

  &__title {
    font-family: $fontBarlow;
    @include vw-value('line-height', 140px, 1125px);
    @include vw-value('font-size', 80px, 1125px);
    margin-bottom: -2vw;
    font-weight: 700;
    color: black;
    text-align: center;
    letter-spacing: 0.04em;

    @include tabletLandscape {
      @include vw-value('font-size', 60px, 2880px);
      @include vw-value('line-height', 100px, 2880px);
      margin-bottom: -0.8vw;
      letter-spacing: 0.04em;
      font-weight: 700;
    }

    img {
      height: auto;
      @include vw-value('width', 635px, 1125px);
      @include vw-value('margin-bottom', 50px, 1125px);
      @include tabletLandscape {
        @include vw-value('width', 635px, 2880px);
        @include vw-value('margin-bottom', 25px, 2880px);
      }
    }
  }

  &__desc {
    @include dparagraphregblack;
    @include vw-value('font-size', 45px, 1125px);
    line-height: 1.4;
    text-align: center;
    letter-spacing: 0.11em;

    @include tabletLandscape {
      width: 100%;
      @include vw-value('font-size', 40px, 2880px);
      @include vw-value('line-height', 50px, 2880px);
      letter-spacing: 0.13em;
      line-height: 1.47em;
    }
  }



  &.managed-text {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0 5vw;
    position: absolute;
    top: 174vw;
    @include tabletLandscape {
      min-height: 16vw;
      margin-top: 0vw;
      margin-bottom: 2vw;
      padding: 0 0 0 10%;
      position: absolute;
      top: 61vw;
      width: 50%;
    }

    .title-with-description {
      &__title {
        font-family: $fontNunito;
        font-weight: 500;
        text-align: center;
        font-size: 6.3vw;
        line-height: 1.2;
        margin-bottom: 3vw;
        text-transform: none;
        @include compensatedLetterSpacing(0.05em);
        @include tabletLandscape {
          text-align: left;
          font-size: 3.4vw;
          margin-bottom: .8vw;
          line-height: 1.45;
          @include compensatedLetterSpacing(0.03em);
        }
      }

      &__desc {
        font-family: $fontNunito;
        text-align: center;
        font-size: 3.8vw;
        line-height: 1.25;
        @include compensatedLetterSpacing(0.15em);
        @include tabletLandscape {
          font-size: 1.3vw;
          line-height: 1.4;
          text-align: left;
        }
      }
    }
  }

  &.servers-text {
    min-height: 6vw;
    margin-top: 8.5vw;
    margin-bottom: 6vw;
    padding: 0 5vw;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 2vw;
      margin-bottom: 2vw;
    }

    .title-with-description {

      &__eyelet {

        @include dparagraphregblack;
        line-height: 1.4;
        text-align: center;
        text-transform: uppercase;
        @include font-size(40px, 40px);
        margin-bottom: 1.8vw;


        @include tabletLandscape {
          margin-bottom: .8vw;
          width: 100%;
          line-height: 1.47em;
        }
      }
      &__title {
        font-weight: 400;
        line-height: 1.3;
        margin-bottom: 5vw;
        @include font-size(60px, 60px);
        @include compensatedLetterSpacing(0.01em);
        @include tabletLandscape {
          margin-bottom: 2vw;
          line-height: 1.2;
          @include compensatedLetterSpacing(0.01em);
        }
      }

      &__desc {
        line-height: 1.6;

        @include font-size(40px, 40px);
        @include tabletLandscape {
          line-height: 1.4;
        }
      }
    }
  }



  &.inprimopiano {
    min-height: 6vw;
    margin-top: 6.5vw;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 0vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        @include vw-value('font-size', 90px, 1125px);
        line-height: 1.1;
        margin-bottom: 2vw;
        margin-top: 2vw;
        @include tabletLandscape {
          @include vw-value('font-size', 80px, 2880px);
          padding-top: 1.5vw;
          margin-bottom: 0.8vw;
        }
      }

      &__desc {
        @include vw-value('font-size', 45px, 1125px);
        line-height: 1.1;
        letter-spacing: 0.1em;
        @include tabletLandscape {
          @include vw-value('font-size', 45px, 2880px);
          margin-bottom: 2vw;
          letter-spacing: 0.08em;
        }
      }
    }
  }

  &--left {
    .title-with-description {
      @include tabletLandscape {
        &__title {
          text-align: left;
        }
        &__desc {
          text-align: left;
        }
      }
    }
  }

  &.our-brands {
    min-height: 13vw;
    @include tabletLandscape {
      min-height: 4vw;
    }

    .title-with-description {
      &__title {
        @include font-size(70px, 70px);
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.4em;
        @include tabletLandscape {

        }
      }
    }
  }

  &.text-services {
    padding: 0;
    @include standardComponentMarginOnlyTop;
    min-height: 13vw;
    @include tabletLandscape {
      min-height: 4vw;
      margin-top: -1vw;
    }

    .title-with-description {
      &__title {
        @include font-size(60px, 60px);
        line-height: 1.2;
        text-transform: uppercase;
        font-weight: normal;
        @include compensatedLetterSpacing(0.15em);
        @include tabletLandscape {
          line-height: 1;
          margin: 1vw 0 2vw;
        }
      }
    }
  }

  &.new-collection2021 {
    min-height: 6vw;
    margin-top: 6.5vw;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 3.5vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        font-size: 6.05vw;
        line-height: 1.1;
        margin-bottom: 2vw;
        margin-top: 2vw;
        @include tabletLandscape {
          font-size: 2.5888888vw;
          margin-bottom: 0.8vw;
          margin-top: 0vw;
        }
      }

      &__desc {
        font-size: 4vw;
        line-height: 1.1;
        letter-spacing: 0.1em;
        @include tabletLandscape {
          font-size: 1.6vw;
          margin-bottom: 1vw;
        }
      }
    }
  }

  &.new-collection2021-1 {
    min-height: 6vw;
    margin-top: 6.5vw;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 0vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        font-size: 6.05vw;
        line-height: 1.1;
        margin-bottom: 2vw;
        margin-top: 2vw;
        @include tabletLandscape {
          font-size: 2.5888888vw;
          padding-top: 1.5vw;
          margin-bottom: 0.5vw;
        }
      }

      &__desc {
        font-size: 3.8vw;
        line-height: 1.1;
        letter-spacing: 0.08em;
        @include tabletLandscape {
          font-size: 1.6vw;
          margin-bottom: 2vw;
        }
      }
    }
  }

  &.text-gap-index {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 0vw;
      margin-bottom: 2vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        font-size: 5.95vw;
        line-height: 1.1;
        margin-bottom: 5vw;
        text-transform: uppercase;
        @include compensatedLetterSpacing(0.4em);

        &:lang(en) {
          @include compensatedLetterSpacing(0.25em);
        }

        @include tabletLandscape {
          font-size: 2.4vw;
          margin-bottom: 2vw;
          line-height: 1;
          @include compensatedLetterSpacing(0.2em);
        }
      }

      &__desc {
        font-size: 4.5vw;
        line-height: 1.25;
        @include compensatedLetterSpacing(0.15em);

        &:lang(en) {
          font-size: 3.5vw;
        }

        &:lang(es) {
          font-size: 3.8vw;
        }

        @include tabletLandscape {
          font-size: 1.15vw;
          line-height: 1.3;
          &:lang(en) {
            font-size: 1.15vw;
          }
          &:lang(es) {
            font-size: 1.15vw;
          }
        }
      }
    }
  }

  &.text-gap {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 0vw;
      margin-bottom: 2vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        font-size: 5.95vw;
        line-height: 1.1;
        margin-bottom: 2vw;
        margin-top: 2vw;
        @include tabletLandscape {
          font-size: 2.4vw;
          padding-top: 1.5vw;
          margin-bottom: 1.5vw;
          line-height: 1;
        }
      }

      &__desc {
        font-size: 3.6vw;
        line-height: 1.1;
        letter-spacing: 0.08em;

        &:lang(en) {
          font-size: 3.5vw;
        }

        @include tabletLandscape {
          font-size: 1.15vw;
          margin-bottom: 2vw;
          line-height: 1.3;
          &:lang(en) {
            font-size: 1.15vw;
          }
        }
      }
    }
  }

  &.text-collection-oradaovs {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 0;
      margin-bottom: 0;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        @include font-size(55px, 63px);
        line-height: 1.1;
        margin-bottom: 0;
        margin-top: 2vw;
        text-transform: uppercase;

        &:lang(es) {
          @include font-size(51px, 63px);
        }

        @include compensatedLetterSpacing(0.2em);
        @include tabletLandscape {
          padding-top: 0;
          margin-bottom: 0;
          line-height: 0.5;
          letter-spacing: 0.1em;
          margin-top: 1vw;
        }
      }

      &__desc {
        @include font-size(144px, 165px);
        line-height: 1.1;
        font-weight: bold;
        text-transform: uppercase;
        @include compensatedLetterSpacing(0.05em);
        @include tabletLandscape {
          margin-bottom: 0;
          line-height: 1.3;
        }
      }
    }
  }

  &.text-collection-kids {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 0;
      margin-bottom: 2vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        @include font-size(50px, 50px);
        line-height: 1.1;
        margin-bottom: 2vw;
        margin-top: 2vw;
        text-transform: uppercase;
        @include compensatedLetterSpacing(0.25em);
        @include tabletLandscape {
          padding-top: 1.5vw;
          margin-bottom: 1.5vw;
          line-height: 1;
          letter-spacing: 0.1em;
          margin-top: 1vw;
        }
      }

      &__desc {
        @include font-size(44px, 50px);
        line-height: 1.1;
        font-style: italic;
        font-weight: normal;
        @include compensatedLetterSpacing(0.05em);
        @include tabletLandscape {
          margin-bottom: 2vw;
          line-height: 1.3;
          &:lang(es) {
            font-size: 1.65vw;
          }
        }
      }
    }
  }

  &.text-collection-kids-middle {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 2vw;
      margin-bottom: 1vw;
    }

    .title-with-description {
      &__desc {
        @include font-size(42px, 50px);
        line-height: 1.2;
        font-style: italic;
        font-weight: normal;
        @include compensatedLetterSpacing(0.05em);
        @include tabletLandscape {
          margin-bottom: 2vw;
          line-height: 1.3;
        }
      }
    }
  }

  &.text-collection-kids-hub {
    min-height: 6vw;
    padding: 6.5vw 0 10vw;
    @include tabletLandscape {
      min-height: 5vw;
      margin: 0;
      padding: 1vw 0 3vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        @include font-size(65px, 65px);
        line-height: 1.1;
        margin-bottom: 2vw;
        margin-top: 2vw;
        text-transform: uppercase;
        @include compensatedLetterSpacing(0.25em);
        @include tabletLandscape {
          padding-top: 1.5vw;
          margin-bottom: 1.5vw;
          line-height: 1;
          letter-spacing: 0.1em;
        }
      }

      &__desc {
        @include font-size(40px, 40px);
        line-height: 1.1;
        font-style: italic;
        font-weight: normal;
        @include compensatedLetterSpacing(0.05em);
        @include tabletLandscape {
          margin-bottom: 2vw;
          line-height: 1.3;
        }
      }
    }
  }

  &.text-collection-kids-hub-brand {
    min-height: 6vw;
    padding: 6.5vw 0 10vw;
    @include tabletLandscape {
      min-height: 5vw;
      margin: 0;
      padding: 0;
    }

    .title-with-description {
      &__desc {
        @include font-size(38px, 40px);
        line-height: 1.2;
        font-style: italic;
        font-weight: normal;
        @include compensatedLetterSpacing(0.05em);
        @include tabletLandscape {
          margin-bottom: 2vw;
          line-height: 1.3;
        }
      }
    }
  }

  &.giaccheditransizione2021 {
    min-height: 24vw;
    margin-top: 12.5vw;
    margin-bottom: -1.7vw;
    @include tabletLandscape {
      min-height: 6.5vw;
      margin-top: 3.5vw;
      margin-bottom: 0;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        font-size: 6.5vw;
        line-height: 1.1;
        margin-bottom: 3.5vw;
        margin-top: 4vw;
        text-transform: none;
        font-family: $fontPlayfair;
        @include compensatedLetterSpacing(0.1em);
        @include tabletLandscape {
          font-size: 2.3vw;
          margin-bottom: 0.8vw;
          margin-top: 0;
          @include compensatedLetterSpacing(0.15em);
        }
      }

      &__desc {
        font-size: 4vw;
        line-height: 1.2;
        letter-spacing: 0.1em;
        @include tabletLandscape {
          font-size: 1.3vw;
          margin-bottom: 1vw;
        }
      }
    }
  }

  &.text-comfort {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 0vw;
      margin-bottom: 2vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        font-size: 5.9vw;
        line-height: 1.2;
        margin-bottom: 3vw;
        text-transform: uppercase;
        @include compensatedLetterSpacing(0.3em);
        @include tabletLandscape {
          font-size: 2.1vw;
          margin-bottom: .8vw;
          line-height: 1;
          @include compensatedLetterSpacing(0.4em);
        }
      }

      &__desc {
        font-size: 4.5vw;
        line-height: 1.25;
        @include compensatedLetterSpacing(0.15em);

        &:lang(en) {
          font-size: 3.5vw;
        }

        &:lang(es) {
          font-size: 3.8vw;
        }

        @include tabletLandscape {
          font-size: 1.3vw;
          line-height: 1.3;
          &:lang(en) {
            font-size: 1.15vw;
          }
          &:lang(es) {
            font-size: 1.15vw;
          }
        }
      }
    }
  }

  &.text-infinite {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 1vw;
      margin-top: 0vw;
      margin-bottom: 2vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        font-size: 5.8vw;
        line-height: 1.2;
        margin-bottom: 3vw;
        text-transform: uppercase;
        @include compensatedLetterSpacing(0.3em);
        &:lang(en) {
          font-size: 5.6vw;
          @include compensatedLetterSpacing(0.25em);
        }
        @include tabletLandscape {
          font-size: 2.1vw;
          margin-bottom: .8vw;
          line-height: 1;
          @include compensatedLetterSpacing(0.4em);
          &:lang(en) {
            font-size: 2.1vw;
            @include compensatedLetterSpacing(0.4em);
          }
        }
      }

      &__desc {
        font-size: 4.5vw;
        line-height: 1.25;
        @include compensatedLetterSpacing(0.15em);

        &:lang(en) {
          font-size: 3.5vw;
        }

        &:lang(es) {
          font-size: 3.8vw;
        }

        @include tabletLandscape {
          font-size: 1.3vw;
          line-height: 1.3;
          &:lang(en) {
            font-size: 1.15vw;
          }
          &:lang(es) {
            font-size: 1.15vw;
          }
        }
      }
    }
  }
  &.text-infinite-kids {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 1vw;
      margin-top: -1.5vw;
      margin-bottom: -1vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        font-size: 5.8vw;
        line-height: 1.2;
        margin-bottom: 3vw;
        text-transform: uppercase;
        @include compensatedLetterSpacing(0.3em);
        &:lang(en) {
          font-size: 5.6vw;
          @include compensatedLetterSpacing(0.25em);
        }
        @include tabletLandscape {
          font-size: 2.1vw;
          margin-bottom: .8vw;
          line-height: 1;
          @include compensatedLetterSpacing(0.4em);
          &:lang(en) {
            font-size: 2.1vw;
            @include compensatedLetterSpacing(0.4em);
          }
        }
      }

      &__desc {
        font-size: 4.5vw;
        line-height: 1.25;
        @include compensatedLetterSpacing(0.15em);

        &:lang(en) {
          font-size: 3.5vw;
        }

        &:lang(es) {
          font-size: 3.8vw;
        }

        @include tabletLandscape {
          font-size: 1.3vw;
          line-height: 1.3;
          &:lang(en) {
            font-size: 1.15vw;
          }
          &:lang(es) {
            font-size: 1.15vw;
          }
        }
      }
    }
  }


  &.text-wellness {
    min-height: 6vw;
    margin-top: 6.5vw;
    margin-bottom: 6vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 5vw;
      margin-top: 0vw;
      margin-bottom: 2vw;
    }

    .title-with-description {
      &__title {
        font-weight: bold;
        font-size: 5.95vw;
        line-height: 1.1;
        margin-bottom: 5vw;
        text-transform: uppercase;
        @include compensatedLetterSpacing(0.4em);
        @include tabletLandscape {
          font-size: 2.4vw;
          margin-bottom: 2vw;
          line-height: 1;
          @include compensatedLetterSpacing(0.6em);
        }
      }

      &__desc {
        font-size: 4.5vw;
        line-height: 1.25;
        @include compensatedLetterSpacing(0.15em);

        &:lang(en) {
        }

        &:lang(es) {
        }

        @include tabletLandscape {
          font-size: 1.3vw;
          line-height: 1.4;
          &:lang(en) {
          }
          &:lang(es) {
          }
        }
      }
    }
  }

  &.text-wellness2 {
    min-height: 6vw;
    margin: 2vw 0;
    padding: 0;
    @include tabletLandscape {
      min-height: 3vw;
      margin-top: 1vw;
      margin-bottom: 1vw;
    }

    .title-with-description {
      &__desc {
        font-size: 4.2vw;
        line-height: 1.25;
        @include compensatedLetterSpacing(0.15em);

        &:lang(en) {
        }

        &:lang(es) {
        }

        @include tabletLandscape {
          font-size: 1.35vw;
          line-height: 1.4;
          &:lang(en) {
          }
          &:lang(es) {
          }
        }
      }
    }
  }

  &.text-wellness3 {
    min-height: 6vw;
    margin: 2vw 0 10vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 3vw;
      margin: 1vw 0 4vw;
    }

    .title-with-description {
    }
  }

  &.text-wellness4 {
    min-height: 6vw;
    margin: 6vw 0 5vw;
    padding: 0;
    @include tabletLandscape {
      min-height: 3vw;
      margin: 2.5vw 0 2vw;
    }

    .title-with-description {
    }
  }

  &.your-contribution {
    min-height: 35vw;
    @include tabletLandscape {
      padding-left: 0;
      min-height: 20vw;
    }

    .title-with-description {
      &__title {
        text-transform: uppercase;
        @include font-size(140px, 170px);
        line-height: 1.05;
        letter-spacing: 0.1em;
        text-align: left;
        display: block;
        padding-bottom: 8vw;
        &:lang(en) {
          @include font-size(130px, 170px);
        }
        @include tabletLandscape {
          margin: 0 0 0 14vw;
          padding-bottom: 5vw;
        }
      }

      &__line {
        position: absolute;
        top: 30vw;
        width: 52vw;
        left: 4vw;
        height: 1vw;
        background-color: black;
        @include tabletLandscape {
          top: 18vw;
          width: 20vw;
          left: 14vw;
          height: 0.3vw;
        }
      }
    }
  }

  &.last-title {

    @include tabletLandscape {
      padding-left: 0;
    }

    .title-with-description {
      &__title {
        @include font-size(60px, 60px);
        line-height: 1.05;
        letter-spacing: 0.1em;
        text-align: left;
        display: block;
        padding-bottom: 0;
        margin-bottom: 15vw;
        @include tabletLandscape {
          margin: 3vw 0 5vw 14vw;

        }
      }

      &__desc {
        @include font-size(50px, 45px);
        line-height: 1.1;
        letter-spacing: 0.1em;
        text-align: left;
        display: block;
        margin-bottom: 10vw;
        p {
          margin-bottom: 1.5vw;
        }
        @include tabletLandscape {
          margin: 6vw 0 5vw 14vw;
        }
      }
      &__line {
        position: absolute;
        top: 18vw;
        width: 52vw;
        left: 4vw;
        height: 1vw;
        background-color: black;
        @include tabletLandscape {
          top: 10vw;
          width: 20vw;
          left: 14vw;
          height: 0.3vw;
        }
      }
    }
  }
  &.text-cake-animation {
    margin: 0;
    padding: 0;
    @include tabletLandscape {
    }

    .title-with-description {
      &__title {
        margin: 0;
        @include font-size(80px, 70px);
        line-height: 1.05;
        letter-spacing: 0.2em;
        text-align: left;
        text-transform: uppercase;
        @include tabletLandscape {
          padding-top: 2vw;
        }
      }

      &__desc {
        margin: 0;
        @include font-size(50px, 45px);
        line-height: 1.3;
        letter-spacing: 0.1em;
        text-align: left;
        display: block;
        padding-top: 19vw;
        @include tabletLandscape {
          padding-top: 8vw;
        }
      }
      &__line {
        position: absolute;
        top: 23vw;
        width: 52vw;
        left: 0vw;
        height: 1vw;
        background-color: black;
        @include tabletLandscape {
          top: 11vw;
          width: 20vw;
          left: 0vw;
          height: 0.3vw;
        }
      }
    }
  }
  &.only-title-earth {
    padding-left: 4vw;
    @include tabletLandscape {
      padding-left: 0;
      margin-top: 1.5vw;
      min-height: 6vw;
    }

    .title-with-description {
      &__title {
        @include font-size(80px, 60px);
        line-height: 1.1;
        letter-spacing: 0.1em;
        text-align: left;
        display: block;
        padding-bottom: 0;
        margin-bottom: 0;
        text-transform: uppercase;
        @include tabletLandscape {
          margin: 3vw 0 0vw 14vw;

        }
      }
    }
  }
  &.only-desc-earth {
    padding-left: 4vw;
    @include tabletLandscape {
      padding-left: 0;
      margin-bottom: 0;
    }

    .title-with-description {
      &__desc {
        font-weight: bold;
        font-style: italic;
        margin: 0;
        @include font-size(52px, 52px);
        line-height: 1.4;
        letter-spacing: 0.1em;
        text-align: left;
        display: block;
        padding-top: 0;
        @include tabletLandscape {
          margin: 3vw 0 0vw 14vw;
          letter-spacing: 0.15em;
        }
      }
    }
  }
}
