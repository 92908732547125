

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Khula:wght@400;600;700;800&display=swap');

@mixin dpriceregwhite {
  font-family: $fontBarlow;
  font-size: 50px;
  color: $white;
}

@mixin dpricereggrey {
  font-family: $fontBarlow;
  font-size: 50px;
  color: $brownish-grey;
}

@mixin dpriceregblack {
  font-family: $fontBarlow;
  font-size: 50px;
  line-height: 0.6;
  color: $kind-of-black;
}

@mixin mpriceregwhite {
  font-family: $fontBarlow;
  font-size: 40px;
  color: $white;
}

@mixin mpricereggrey {
  font-family: $fontBarlow;
  font-size: 40px;
  color: $brownish-grey;
}

@mixin mpriceregblack {
  font-family: $fontBarlow;
  font-size: 40px;
  color: $kind-of-black;
}

@mixin dtitleregwhite {
  font-family: $fontBarlow;
  font-size: 35px;
  line-height: 1.49;
  color: $white;
}

@mixin dtitlereggrey {
  font-family: $fontBarlow;
  font-size: 35px;
  line-height: 1.49;
  color: $brownish-grey;
}

@mixin dtitleregblack {
  font-family: $fontBarlow;
  font-size: 35px;
  line-height: 1.49;
  color: $kind-of-black;
}

@mixin mtitleregwhite {
  font-family: $fontBarlow;
  font-size: 30px;
  line-height: 1.33;
  color: $white;
}

@mixin mtitlereggrey {
  font-family: $fontBarlow;
  font-size: 30px;
  line-height: 1.33;
  color: $brownish-grey;
}

@mixin mtitleregblack {
  font-family: $fontBarlow;
  font-size: 30px;
  line-height: 1.33;
  color: #000000;
}

@mixin mpricemedwhite {
  font-family: $fontBarlow;
  font-size: 28px;
  color: $white;
}

@mixin mpricemedgrey {
  font-family: $fontBarlow;
  font-size: 28px;
  color: $brownish-grey;
}

@mixin dpricemedwhite {
  font-family: $fontBarlow;
  font-size: 28px;
  line-height: 0.86;
  color: $white;
}

@mixin dpricemedgrey {
  font-family: $fontBarlow;
  font-size: 28px;
  line-height: 0.86;
  color: $brownish-grey;
}

@mixin dpricemedblack {
  font-family: $fontBarlow;
  font-size: 28px;
  line-height: 0.86;
  color: $kind-of-black;
}

@mixin mpricemedblack {
  font-family: $fontBarlow;
  font-size: 26px;
  color: $kind-of-black;
}

@mixin Text-Style-2 {
  font-family: $fontBarlow;
  font-size: 26px;
  text-align: right;
  color: $kind-of-black;
}

@mixin mhighlightregwhite {
  font-family: $fontBarlow;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  color: $white;
}

@mixin mhighlightreggrey {
  font-family: $fontBarlow;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  color: $brownish-grey;
}

@mixin mhighlightregblack {
  font-family: $fontBarlow;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  color: $kind-of-black;
}

@mixin mtitlemedwhite {
  font-family: $fontBarlow;
  font-size: 20px;
  line-height: 1.8;
  color: $white;
}

@mixin mtitlemedgrey {
  font-family: $fontBarlow;
  font-size: 20px;
  line-height: 1.8;
  color: $brownish-grey;
}

@mixin mtitlemedblack {
  font-family: $fontBarlow;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: $kind-of-black;
}

@mixin mparagraphregblack {
  font-family: $fontBarlow;
  @include vw-value('font-size', 20px, 375px);
  @include tabletLandscape {
    @include vw-value('font-size', 20px, 1440px);
  }
  line-height: 1.8;
  color: $kind-of-black;
}

@mixin mlinkregwhite {
  font-family: $fontBarlow;
  font-size: 20px;
  color: $white;
}

@mixin mlinkreggrey {
  font-family: $fontBarlow;
  font-size: 20px;
  color: $brownish-grey;
}

@mixin mlinkregblack {
  font-family: $fontBarlow;
  font-size: 20px;
  color: $kind-of-black;
}

@mixin dtitlemedwhite {
  font-family: $fontBarlow;
  font-size: 20px;
  line-height: 1.25;
  color: $white;
}

@mixin dtitlemedgrey {
  font-family: $fontBarlow;
  font-size: 20px;
  line-height: 1.25;
  color: $brownish-grey;
}

@mixin dtitlemedblack {
  font-family: $fontBarlow;
  font-size: 20px;
  line-height: 1.25;
  color: $kind-of-black;
}

@mixin mpricesmallwhite {
  font-family: $fontBarlow;
  font-size: 19px;
  color: $white;
}

@mixin mpricesmallgrey {
  font-family: $fontBarlow;
  font-size: 19px;
  color: $brownish-grey;
}

@mixin mpricesmallblack {
  font-family: $fontBarlow;
  font-size: 18px;
  color: $kind-of-black;
}

@mixin dhighlightregwhite {
  font-family: $fontBarlow;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.53;
  color: $white;
}

@mixin dhighlightreggrey {
  font-family: $fontBarlow;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.53;
  color: $brownish-grey;
}

@mixin dhighlightregblack {
  font-family: $fontBarlow;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.53;
  color: $kind-of-black;
}

@mixin dtitlesmallwhite {
  font-family: $fontBarlow;
  font-size: 17px;
  line-height: 1.47;
  color: $white;
}

@mixin dtitlesmallgrey {
  font-family: $fontBarlow;
  font-size: 17px;
  line-height: 1.47;
  color: $brownish-grey;
}

@mixin dtitlesmallblack {
  font-family: $fontBarlow;
  font-size: 17px;
  line-height: 1.47;
  color: $kind-of-black;
}

@mixin dpricesmallwhite {
  font-family: $fontBarlow;
  font-size: 17px;
  color: $white;
}

@mixin dpricesmallgrey {
  font-family: $fontBarlow;
  font-size: 17px;
  color: $brownish-grey;
}

@mixin dpricesmallblack {
  font-family: $fontBarlow;
  font-size: 17px;
  color: $kind-of-black;
}

@mixin dparagraphregwhite {
  font-family: $fontBarlow;
  font-size: 17px;
  line-height: 1.53;
  color: $white;
}

@mixin dparagraphreggrey {
  font-family: $fontBarlow;
  font-size: 17px;
  line-height: 1.53;
  color: $brownish-grey;
}

@mixin dparagraphregblack {
  font-family: $fontBarlow;
  font-size: 17px;
  line-height: 1.53;
  color: $kind-of-black;
}

@mixin mhighlightmedwhite {
  font-family: $fontBarlow;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;
  color: $white;
}

@mixin mhighlightmedgrey {
  font-family: $fontBarlow;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;
  color: $brownish-grey;
}

@mixin mhighlightmedblack {
  font-family: $fontBarlow;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;
  color: $kind-of-black;
}

@mixin mtitlesmallwhite {
  font-family: $fontBarlow;
  font-size: 15px;
  line-height: 1.47;
  color: $white;
}

@mixin mtitlesmallgrey {
  font-family: $fontBarlow;
  font-size: 15px;
  line-height: 1.47;
  color: $brownish-grey;
}

@mixin mtitlesmallblack {
  font-family: $fontBarlow;
  font-size: 15px;
  line-height: 1.47;
  color: $kind-of-black;
}

@mixin mlinkmedwhite {
  font-family: $fontBarlow;
  font-size: 15px;
  letter-spacing: 0px;
  color: $white;
}

@mixin mlinkmedgrey {
  font-family: $fontBarlow;
  font-size: 15px;
  letter-spacing: 0px;
  color: $brownish-grey;
}

@mixin mlinkmedblack {
  font-family: $fontBarlow;
  font-size: 15px;
  line-height: 2.07;
  letter-spacing: 0px;
  color: $kind-of-black;
}

@mixin mhighlightsmallwhite {
  font-family: $fontBarlow;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.46;
  color: $white;
}

@mixin mhighlightsmallgrey {
  font-family: $fontBarlow;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.46;
  color: $brownish-grey;
}

@mixin mhighlightsmallblack {
  font-family: $fontBarlow;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.46;
  color: $kind-of-black;
}

@mixin mparagraphsmallgrey {
  font-family: $fontBarlow;
  font-size: 13px;
  line-height: 1.46;
  color: $brownish-grey;
}

@mixin mparagraphsmallblack {
  font-family: $fontBarlow;
  font-size: 13px;
  line-height: 1.46;
  text-align: center;
  color: $kind-of-black;
}

@mixin mcolor-selectorblack {
  font-family: $fontBarlow;
  font-size: 13px;
  line-height: 1.46;
  color: $kind-of-black;
}

@mixin dlinksmallwhite {
  font-family: $fontBarlow;
  font-size: 13px;
  letter-spacing: 0px;
  color: $white;
}

@mixin dlinksmallgrey {
  font-family: $fontBarlow;
  font-size: 13px;
  letter-spacing: 0px;
  color: $brownish-grey;
}

@mixin dlinksmallblack {
  font-family: $fontBarlow;
  font-size: 13px;
  letter-spacing: 0px;
  color: $kind-of-black;
}

@mixin Text-Style {
  font-family: $fontBarlow;
  font-size: 13px;
  line-height: 1.46;
  color: $white;
}

@mixin mainTitle {
  font-family: $fontBarlow;
  @include vw-value('font-size', 140px, 1125px);
  line-height: 1.2;
  letter-spacing: 0.05em;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin: 9.3333333333vw 0;

  @include tabletLandscape {
    @include vw-value('font-size', 110px, 2880px);
    margin: 2vw 0 2vw;
  }

}
