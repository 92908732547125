.footer {
  min-height: 199vw;
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg width='390px' height='830px' viewBox='0 0 390 830' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ERectangle Copy 2@2x%3C/title%3E%3Cdefs%3E%3ClinearGradient x1='61.0393381%25' y1='96.2610491%25' x2='44.8975526%25' y2='11.9979765%25' id='linearGradient-1'%3E%3Cstop stop-color='%232040A6' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%231F227D' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Homepage----iPhone-12-Pro' transform='translate(0.000000, -4126.000000)' fill='url(%23linearGradient-1)'%3E%3Crect id='Rectangle-Copy-2' x='0' y='4126' width='390' height='830'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  @include tabletPortrait {
    min-height: 57.29vw;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='1280px' height='437px' viewBox='0 0 1280 437' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EGroup 36%3C/title%3E%3Cdefs%3E%3ClinearGradient x1='100%25' y1='55.3921059%25' x2='26.889704%25' y2='45.5705515%25' id='linearGradient-1'%3E%3Cstop stop-color='%232040A6' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%231F227D' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Homepage' transform='translate(0.000000, -3203.000000)'%3E%3Cg id='Group-36' transform='translate(0.000000, 3203.000000)'%3E%3Crect id='Rectangle' fill='url(%23linearGradient-1)' x='0' y='0' width='1280' height='437'%3E%3C/rect%3E%3Crect id='Rectangle-Copy' fill='%231D207A' x='0' y='380' width='1280' height='57'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

  }
  @include tabletLandscape {
    padding: 0;
    min-height: 34.375vw;
    background-image: url("data:image/svg+xml,%3Csvg width='1280px' height='437px' viewBox='0 0 1280 437' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ERectangle%3C/title%3E%3Cdefs%3E%3ClinearGradient x1='100%25' y1='55.3921059%25' x2='26.889704%25' y2='45.5705515%25' id='linearGradient-1'%3E%3Cstop stop-color='%232040A6' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%231F227D' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Homepage' transform='translate(0.000000, -3203.000000)' fill='url(%23linearGradient-1)'%3E%3Crect id='Rectangle' x='0' y='3203' width='1280' height='437'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }


  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 11vw 6vw;
    @include tabletLandscape {
      flex-direction: row;
      padding: 4vw 3vw 3vw 10vw;
    }
  }


    li {
      font-family: $fontNunito;
      list-style-type: none;
    }

    a {
      text-decoration: none;
      color: #888ebf;
    }


  &__contacts {

    &__logo {
      padding-bottom: 3vw;
      @include tabletLandscape {
        padding-bottom: 1.3vw;
      }
    }

    &__spec {
      div {
        margin: 1.5vw 0;
        @include tabletLandscape {
          margin: .6vw 0;
        }
      }

      &--title {
        font-family: $fontNunito;
        color: $white;
        @include font-size(52px, 50px);
      }

      &--socials {
        font-family: $fontNunito;
        display: flex;
        padding-top: 1.5vw;
        @include tabletLandscape {
          padding-top: 1vw;
        }
      }

      &--social {
        padding-right: 6vw;
        width: 6.2%;
        @include tabletLandscape {
          padding-right: 2vw;
          width: 8%;
        }

        img {
          width: 100%;
        }
      }

      &--telephone {
        font-family: $fontNunito;
        display: flex;
        color: $white;
      }

      &--phone, &--mail {
        font-family: $fontNunito;
        padding-left: 2vw;
        @include tabletLandscape {
          padding-left: 1vw;
        }
      }

      &--mail {
      }
    }
  }
  &__list {
    font-family: $fontNunito;
    color: $white;
  }

  &__contacts {
    @include tabletLandscape {
      width: 30%;
    }
  }
  &__specifications {
    margin: 3.5vw 0;
    @include tabletLandscape {
      margin: 0;
    }

    div {
      margin: 3vw 0;
      @include tabletLandscape {
        margin: 0 0 1vw 0;
      }
    }
    @include tabletLandscape {
      width: 70%;
      display: flex;
      justify-content: space-around;

    }
  }
  &__items {
    li {
      font-family: $fontNunito;
      padding: 0.7vw 0;
      @include tabletLandscape {
        padding: 0.4vw 0;
      }
    }
  }
  &__last-section {
    font-family: $fontNunito;
    width: 100%;
    height: 10vw;
    @include font-size(35px, 35px);
    color: #888ebf;
    background-color: #1d207a;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tabletLandscape {
      height: 4.5vw;
    }
  }
}