// cta overlay behaviour
.ctas-to-be-shown {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20vw;
  box-sizing: border-box;

  &.ctas-to-be-shown-6 {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-end;
    align-content: flex-end;

    .close-button {
      margin-left: calc(50% - 25px);
      margin-right: calc(50% - 25px);
    }
  }
  @include tabletLandscape {
    display: none;
  }

  .close-button {
    width: 50px;
    height: 50px;
    padding-bottom: 10px;
    background-repeat: no-repeat;
    background-position: center top;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='50px' height='50px' viewBox='0 0 50 50' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='components/hp/modules/content-card-reveal-and-slide-2/desktop' transform='translate(-1327.000000, -104.000000)' stroke='%23FFFFFF'%3E%3Cg id='close' transform='translate(1328.000000, 105.000000)'%3E%3Cg id='atoms/icons/close/medium/white' transform='translate(15.000000, 15.000000)' stroke-linecap='round'%3E%3Cpath d='M1.81102854,1.75239536 L17.1203345,17.0617013' id='Path-7'%3E%3C/path%3E%3Cpath d='M1.81102854,1.75239536 L17.1203345,17.0617013' id='Path-7' transform='translate(9.465682, 9.407048) scale(-1, 1) translate(-9.465682, -9.407048) '%3E%3C/path%3E%3C/g%3E%3Ccircle id='Oval' cx='24' cy='24' r='24'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  .hce-btn.cta {
    width: 40vw;
    @include vw-value('margin-top', 20px, 375px);
  }
}

@media all and (max-width: 1023px) {
  .cta-overlay-opened,
  .cta-overlay-opened-tobe {
    .ctas-to-be-shown {
      z-index: 1;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      transform: scale(0);
      background: rgba(0, 0, 0, 0.75);
    }
  }

  .cta-overlay-opened-tobe {
    .ctas-to-be-shown {
      opacity: 0;
      transform: scale(0);
      transition: opacity 0.25s ease-out, transform 0s 0.25s;
    }

    .content-card__text, .to-be-hidden {
      transition: all 0.25s ease-out;
    }
  }

  .cta-overlay-opened {
    overflow: hidden;

    .ctas-to-be-shown {
      opacity: 1;
      transform: scale(1);
      transition: opacity 0.5s 0.01s ease-out, transform 0s;
    }

    .content-card__text, .to-be-hidden {
      transition: all 0.25s ease-out;
      opacity: 0;
    }
  }
}



.overlay-id-1 {
  .ctas-to-be-shown:not(.overlay-id-1) {
    display: none !important;
  }
}

.overlay-id-2 {
  .ctas-to-be-shown:not(.overlay-id-2) {
    display: none !important;
  }
}
